import { React, useMemo, useRef, useState } from "react";
import { useJsApiLoader,useLoadScript,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer } from "@react-google-maps/api";

export default function GoogleMapApi() {
  const originRef = useRef()
  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    // googleMapsApiKey: 'AIzaSyAKHAO3sKkpKsAhu08rOi7CiPpVZZQiYt4',
    //googleMapsApiKey: 'AIzaSyAvSF-WLyQRSnh4dE6UuOOqUsOTA1rmq3Y',
    googleMapsApiKey: 'AIzaSyA-GPCiwhqR_u-g_sMWGSjcCpHE3LFzeWM',
    libraries: ['places'],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return(
  <div>
    <Autocomplete>
        <input type='text' placeholder='Origin' ref={originRef} />
    </Autocomplete>    
    <Map />
  </div>)
  // return <Map />

}

function Map() {
  const center = useMemo(() => ({ lat: 44, lng: -80 }), []);

  return (
    <GoogleMap zoom={10} center={center} mapContainerClassName="map-container">
      <Marker position={center} />
    </GoogleMap>
  );
}
