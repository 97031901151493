/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from 'react'
import { axiosInstance } from '../utils/axios';
import { getUser } from '../utils/storage'
import { useState, useEffect, useRef } from 'react'
import InstagramPost from '../components/post/InstagramPost';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import {
  HeartIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/solid'
import { useNavigate } from 'react-router-dom';
import { useLoadScript,
  Autocomplete
 } from "@react-google-maps/api";

const user = getUser();

export default function PostForm(props) {

  const[instaToken, setInstaToken] = useState('');

  const[caption, setCaption] = useState('');
  const[locationError, setLocationError] = useState('');
  const[selectedFile, setSelectedFile] = useState('');
  const[selectedGallery, setSelectedGallery] = useState([]);
  // const [galleryActiveIndex, setGalleryActiveIndex] = useState(0);
  const[selectedFileError, setSelectedFileError] = useState('');

  // const[tags, setTags] = useState(['HTML', 'JAVASCRIPT', 'CSS', 'PYTHON']);
  // const [postTags, setPostTags] = useState([]);
  const[tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const[tagInput, setTagInput] = useState('');
  const[openDrowdown, setOpenDropdown] = useState(false);

  const [editSelected, setEditSelected] = useState(false);
  const [showInstaPost, setShowInstaPost] = useState(false);

  const[postalCode, setPostalCode] = useState('');
  const[city, setCity] = useState('');
  const[street, setStreet] = useState('');
  const[streetNumber, setStreetNumber] = useState('');
  const[state, setState] = useState('');
  const[country, setCountry] = useState('');
  const[lat, setLat] = useState('');
  const[long, setLong] = useState('');
  const[zip, setZip] = useState('');


  const[instaPostObj, setInstaPostObj] = useState(null);
  const { id } = useParams();
  const { i18n, t } = useTranslation();

  const locationRef = useRef()

  useEffect(()=>{
    if(id){
      getPost();
      getPostTags();
    }
    getTags();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('access_token');
    if(token){
      // const token = urlParams.get('access_token');
      setInstaToken(token);
      setShowInstaPost(true);
    }
  },[])

  async function getPostTags(){
    const res = await axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/posts/'+id+'/tags');
   
    let data = await res.data;
    // setSelectedTags(data);
    data.forEach((tag)=>{
      setSelectedTags([...selectedTags, tag.name.en])
    })
  }

  function getTags(){
    axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/tags/type/posts')
    .then(function (response) {

          // setTags(response.data);
    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }

  function getSearchTags(value){
    if(value != ""){
      setTags([]);
      axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/tags/posts/'+value)
      .then(function (response) {
            let tagNames = [];
            // setTags(response.data);
            if(response.data.length>0){
              response.data.map((tag)=>
              // setTags([...tags, tag.name.en])
              // setTags(tag.name.en)
              tagNames.push(tag.name.en)
              )
  
              setTags(tagNames);
              setOpenDropdown(true);
            }
           
  
      })
      .catch(function (error) {
          // handle error
          console.log(error);
      })
    }
   
  }

  function onFileSlected(e){
    setSelectedFile(e.target.files[0]);
    if(id){
      setEditSelected(true);
    }
  }

  // function onMultiFilesSelected(e){
  //   const imagesArray = [];

  //   for (let i = 0; i < e.target.files.length; i++) {      
  //     imagesArray.push(e.target.files[i]);
  //   }
  //   console.log("gallery", imagesArray);
  //   setSelectedGallery(imagesArray);
  // }

  async function getPost(){
    await axiosInstance.get(window.REACT_APP_API_ENDPOINT+'/api/posts/'+id)
    .then(function (response) {

        locationRef.current.value = response.data.location;
        setCaption(response.data.caption);
        setSelectedFile(window.REACT_APP_API_ENDPOINT+""+response.data.file);

    })
    .catch(function (error) {
        // handle error
        console.log(error);
    })
  }

  function onDropdownClick(e){
    e.preventDefault();
    setOpenDropdown(!openDrowdown);
  }

  function onTagClick(e){
    setSelectedTags([...selectedTags, e.target.value])
    setTags((tags.filter(item => item !== e.target.value)))
    setTagInput("");
  }

  function onTagRemove(e){
    setTags([...tags, e.target.value])
    setSelectedTags((selectedTags.filter(item => item !== e.target.value)))
  }

  function onTagInputChange(e){
    setTagInput(e.target.value);
    // setTags([]);
    getSearchTags(e.target.value);
    
    // if (tags.some(item => item.startsWith(e.target.value))) {
    //   console.log("iteeeem", item);
    // }
  }

  function GetLatlong(address) {
    var geocoder = new google.maps.Geocoder();
  
    geocoder.geocode({
      'address': address
    }, function(results, status) {
  
      if (status == google.maps.GeocoderStatus.OK) {
        let latitude = results[0].geometry.location.lat();
        let longitude = results[0].geometry.location.lng();
        
        getReverseGeocodingData(latitude, longitude);
  
      }
    });
  }

  function getReverseGeocodingData(lat, lng) {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
   
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ 'latLng': latlng }, function (results, status) {
        if (status !== google.maps.GeocoderStatus.OK) {
            alert(status);
        }
        // This is checking to see if the Geoeode Status is OK before proceeding
        if (status == google.maps.GeocoderStatus.OK) {
            console.log(results);
            // var address = (results[0].formatted_address);


            // // the city
            // var city = address.locality;
            // console.log("city", city);
    
            // // the state
            // var state = address.administrative_area_level_1;
            // console.log("state", state);

          

            results[0].address_components.forEach(function(element2){
              element2.types.forEach(function(element3){

                switch(element3){
                  case 'postal_code':
                    setPostalCode(element2.long_name);
                    break;
                  case 'administrative_area_level_1':
                    setState(element2.long_name);
                    break;
                  case 'route':
                      setStreet(element2.long_name);
                      break;
                  case 'locality':
                    setCity(element2.long_name);
                    break;
                  case 'street_number':
                    setStreetNumber(element2.long_name);
                    break;
                  case 'country':
                    setCountry(element2.short_name);
                    break;
                }
              })
            })


            setLat(lat);
            setLong(lng);

        }
    });

}

  function handleTagKeyDown(e){
    if(e.key === 'Enter' && tagInput != ""){
      if(tags && !tags.includes(e.target.value)){
        setSelectedTags([...selectedTags, tagInput])
        setTagInput("");
      }
    }
  }

  function onPostCreate(e){
    e.preventDefault();

    setSelectedFileError('');
    setLocationError('');

    if(locationRef.current.value == ''){
      setLocationError('Location is required!');
      return;
    }

    if(selectedFile == ''){
      setSelectedFileError('Image is required!');
      return;
    }

    else{



      const formData = new FormData();
      formData.append("caption", caption);
      formData.append("location", locationRef.current.value);
      formData.append("lat", lat);
      formData.append("long", long);
      formData.append("street", street);
      formData.append("zip", zip);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("country", country);

      formData.append("tags", selectedTags);
      formData.append("file", selectedFile);
      // formData.append("user_id", user.id);
      // formData.append("gallery", selectedGallery);
      selectedGallery.forEach((image_file) => {
        formData.append('gallery[]', image_file);
      });

      axiosInstance
      .post(window.REACT_APP_API_ENDPOINT+"/api/posts", formData)
      .then((response) => {
        console.log(response);

        if(response.status == 200){
          // setSuccessMsg(true);
          // getSickLeaves();
          window.location.assign('/'+i18n.language+"/profile/"+user.username);
          // navigate('/'+i18n.language+"/profile");
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
    }
  }

  // function onPrevClick(){
  //   if(galleryActiveIndex != 0){
  //     setGalleryActiveIndex((galleryActiveIndex) => galleryActiveIndex - 1);
  //   }
  //   else{
  //     setGalleryActiveIndex(selectedGallery.length);
  //   }
  // }

  // function onNextClick(){
  //   if(galleryActiveIndex != selectedGallery.length){
  //     setGalleryActiveIndex((galleryActiveIndex) => galleryActiveIndex + 1);
  //   }
  //   else{
  //     setGalleryActiveIndex(0);
  //   }
  // }

  async function instaPost (data) {
    let blob = await fetch(data.media_url).then(r => r.blob());
    setInstaPostObj(data);
    setCaption(data.caption == 'undefined'? '' : data.caption);
    setSelectedFile(blob)
  }

  function onInstaPostShow() {
    window.location.assign("https://api.instagram.com/oauth/authorize?app_id=1182850595612953&redirect_uri=https://hotelgramer-api.dev-plus.ch/login/instagram/callback&scope=user_profile,user_media&response_type=code");
      // setShowInstaPost(true);
  }

  function onPlaceChanged(){
    GetLatlong(locationRef.current.value);
  }

  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
    // googleMapsApiKey: 'AIzaSyCHtWbX8q4T_KTyTasNi9btO5fGoTZRuys',
    googleMapsApiKey: 'AIzaSyA-GPCiwhqR_u-g_sMWGSjcCpHE3LFzeWM',
    libraries: ['places'],
  });

  if (!isLoaded) return <div>Loading...</div>;

  else

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div>
              {/* <h3 className="text-lg font-medium leading-6 text-gray-900">Profile</h3> */}

             
              <p className="mt-1 text-sm text-gray-600">
                This information will be displayed publicly so be careful what you share.
              </p>
              
                <li className="bg-white px-4 py-6 shadow sm:p-6 sm:rounded-lg list-none">
                  <article aria-labelledby={'question-title-'}>
                    <div>
                      <div className="flex space-x-3">
                        <div className="flex-shrink-0">
                          <img className="h-10 w-10 rounded-full" src={ user.file ? user.file : 'https://imgur.com/E0h3MGt.png'} alt="" />
                        </div>
                        <div className="min-w-0 flex-1">
                          <p className="text-sm font-medium text-gray-900 text-left">
                            <a href="#" className="hover:underline">
                              {user.name} {user.last_name}
                            </a>
                          </p>
                          <p className="text-sm text-gray-500 text-left">
                            <a href="#" className="hover:underline">
                              <time dateTime={new Date().toDateString().split(' ').slice(1).join(' ')}>{new Date().toDateString().split(' ').slice(1).join(' ')}</time>
                            </a>
                          </p>
                        </div>
                      
                      </div>
                      
                    </div>
                    <div
                      className="mt-2 text-sm text-gray-700 space-y-4"
                    >
                        <div className="flex-shrink-0">
                          {/* {selectedFile && selectedGallery.length > 0 && 
                          <div id="default-carousel" className="relative" data-carousel="static">

                          <div className="overflow-hidden relative h-56 rounded-lg sm:h-64 xl:h-80 2xl:h-96">

                          {selectedGallery.map((img, index)=>
                            <div className={`duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20 ${galleryActiveIndex == index? 'block':'hidden'}`} data-carousel-item={index}>
                              <img src={URL.createObjectURL(img)} className="block h-full object-cover w-full" alt="..."/>
                            </div>
                          )}
                          <div className={`test duration-700 ease-in-out absolute inset-0 transition-all transform translate-x-0 z-20 ${galleryActiveIndex == selectedGallery.length? 'block':'hidden'}`} data-carousel-item={selectedGallery.length}>
                            <img className="block h-full object-cover w-full" src={instaPost? selectedFile : URL.createObjectURL(selectedFile)} alt="" />
                          </div>

                        
                          </div>

                      

                          <button onClick={onPrevClick} type="button" className="flex absolute top-0 left-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-prev="">
                          <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                          <svg className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path></svg>
                          <span className="hidden">Previous</span>
                          </span>
                          </button>
                          <button onClick={onNextClick} type="button" className="flex absolute top-0 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none" data-carousel-next="">
                          <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                          <svg className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path></svg>
                          <span className="hidden">Next</span>
                          </span>
                          </button>
                          </div>} */}
                          
                          {!selectedFile && selectedGallery.length <= 0 && <img className="h-48 w-full object-cover" src={"https://i.imgur.com/BtUbR6w.png"} alt="" />}
                          {(selectedFile && !id && instaPostObj == null && selectedGallery.length <= 0) && <img className="h-48 w-full object-cover" src={URL.createObjectURL(selectedFile)} alt="" />}
                          {(selectedFile && !id && instaPostObj != null && selectedGallery.length <= 0) && <img className="h-48 w-full object-cover" src={instaPostObj.media_url} alt="" />}
                          {(selectedFile && id && !editSelected && selectedGallery.length <= 0) && <img className="h-48 w-full object-cover" src={selectedFile} alt="" />}
                          {(selectedFile && id && editSelected && selectedGallery.length <= 0) && <img className="h-48 w-full object-cover" src={URL.createObjectURL(selectedFile)} alt="" />}

                        </div>
                        {selectedTags.map((tag)=>
                          <a className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#{tag} </a>
                        )}
                        <p className="text-left">{caption}</p>
                    </div>
                    <div className="mt-6 flex justify-between space-x-8">
                      <div className="flex space-x-6">
                        <span className="inline-flex items-center text-sm">
                          <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                            <HeartIcon className="h-5 w-5" aria-hidden="true" color={"currentColor"}/>
                            
                          </button>
                        </span>
                        {/* <span className="inline-flex items-center text-sm">
                          <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                            <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
                            <span className="font-medium text-gray-900">{"11"}</span>
                            <span className="sr-only">replies</span>
                          </button>
                        </span>
                        <span className="inline-flex items-center text-sm">
                          <button type="button" className="inline-flex space-x-2 text-gray-400 hover:text-gray-500">
                            <EyeIcon className="h-5 w-5" aria-hidden="true" />
                            <span className="font-medium text-gray-900">{"2.7k"}</span>
                            <span className="sr-only">views</span>
                          </button>
                        </span> */}
                      </div>
                   
                    </div>
                  </article>
                </li>
            </div>
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
              <div className="flex-shrink-0 mb-2">
                  <a
                  onClick={onInstaPostShow}
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primaryColor shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span>Import from instagram</span>
                  </a>
                </div>
            <form>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">

          


                  <div>
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      Hotel
                    </label>
                    <div className="mt-1">
                    <Autocomplete onPlaceChanged={onPlaceChanged}>
                      <input
                        id="location"
                        type="text"
                        name="location"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Location"
                        ref={locationRef}
                        />
                      </Autocomplete> 
                        {locationError && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                            {locationError}
                        </span>}
                    </div>
                  </div>
                
                  <div>
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      Caption
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        placeholder="you@example.com"
                        value={caption}
                        onChange={(e)=>{setCaption(e.target.value)}}
                      />
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Brief description for your profile. URLs are hyperlinked.
                    </p>
                  </div>


                  {/* <Multiselect
options={options} // Options to display in the dropdown
selectedValues={selected} // Preselected value to persist in dropdown

displayValue="name" // Property name to display in the dropdown options
/> */}



                  <div className="w-full flex flex-col items-center mx-auto">
                      <div className="w-full">
                          <div className="flex flex-col items-center relative">
                              <div className="w-full  svelte-1l8159u">
                              <label for="about" className="block text-sm font-medium text-gray-700">Tags</label>
                                  <div className="my-2 p-1 flex border border-gray-200 bg-white rounded svelte-1l8159u">
                                      <div className="flex flex-auto flex-wrap">
                                        
                                          {selectedTags.map((tag)=>
                                                  <div className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-teal-700 bg-teal-100 border border-teal-300 ">
                                                  <div className="text-xs font-normal leading-none max-w-full flex-initial">{tag}</div>
                                                  <div className="flex flex-auto flex-row-reverse">
                                                      <div className="relative">
                                                          <input className="absolute inset-0 w-4 h-4 ml-2 opacity-0 cursor-pointer border-gray-300 rounded-md" value={tag} onClick={onTagRemove}/>
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x cursor-pointer hover:text-teal-400 rounded-full w-4 h-4 ml-2">
                                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                                          </svg>
                                                      </div>
                                                  </div>
                      
                                              </div>
                                          )}
                                        
                                          <div className="flex-1">
                                              <input placeholder="" className="bg-transparent p-1 px-2 appearance-none outline-none h-full w-full text-gray-800" value={tagInput} onChange={onTagInputChange} onKeyDown={handleTagKeyDown}/>
                                          </div>
                                      </div>
                                      <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-gray-200 svelte-1l8159u" onClick={onDropdownClick}>
                                          <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up w-4 h-4">
                                                  <polyline points="18 15 12 9 6 15"></polyline>
                                              </svg>
                                          </button>
                                      </div>
                                  </div>
                              </div>
                              {openDrowdown && <div className="absolute shadow top-100 bg-white z-40 w-full lef-0 rounded max-h-select overflow-y-auto svelte-5uyqqj">
                                  <div className="flex flex-col w-full">
                                    {tags.map((tag)=>
                                      <div key={tag} className="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
                                        <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
                                            <div className="w-full items-center flex">
                                                <div className="mx-2 leading-6  ">{tag} </div>
                                                <input className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md" value={tag} onClick={onTagClick}/>
                                            </div>
                                        </div>
                                      </div>
                                    )}
                                
                                  </div>
                              </div>}
                          </div>
                      </div>
                  </div>

             
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Cover photo</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="file-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload a file</span>
                            <input onChange={onFileSlected} id="file-upload" name="file-upload" type="file" className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>
                    {selectedFileError && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                        {selectedFileError}
                    </span>}
                  </div>



                  {/* <div>
                    <label className="block text-sm font-medium text-gray-700">Image Gallery</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                      <div className="space-y-1 text-center">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <label
                            htmlFor="files-upload"
                            className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                          >
                            <span>Upload files</span>
                            <input onChange={onMultiFilesSelected} multiple id="files-upload" name="files-upload" type="file" className="sr-only" />
                          </label>
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </div>
                  
                  </div> */}


                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={onPostCreate}
                  >
                    Save
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>

        {showInstaPost && <InstagramPost instaPost={instaPost} accessToken={instaToken}/>}
    
    </>
  )
}