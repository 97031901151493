// /*
//   This example requires Tailwind CSS v2.0+

//   This example requires some changes to your config:

//   ```
//   // tailwind.config.js
//   const colors = require('tailwindcss/colors')

//   module.exports = {
//     // ...
//     theme: {
//       extend: {
//         colors: {
//           rose: colors.rose,
//         },
//       },
//     },
//     plugins: [
//       // ...
//       require('@tailwindcss/forms'),
//     ],
//   }
//   ```
// */

import React from "react";
import { Fragment, useState, useEffect, useRef } from "react";
import { getUser } from "../utils/storage";
import { axiosInstance } from "../utils/axios";
import { useTranslation } from "react-i18next";
import UserListModal from "../components/user/UserListModal";
import Post from "../components/post/Post";
import UserFollow from "../components/user/UserFollow";
import HotelRecommendation from "../components/hotels/HotelRecommendation";

import { useLoadScript,
  Autocomplete
 } from "@react-google-maps/api";
 import Swal from 'sweetalert2';
 import 'sweetalert2/dist/sweetalert2.min.css';
export default function Dashboard() {
    const [posts, setPosts] = useState([]);
    const [users, setUsers] = useState([]);
    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [showFollowersModal, setShowFollowersModal] = useState(false);
    const [showFollowingModal, setShowFollowingModal] = useState(false);
    const [user, setUser] = useState({});
    const [searchedUsersValue, setSearchedUsersValue] = useState([]);
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [favoriteUrl, setFavoriteUrl] = useState(false);
    const [recommendationsUrl, setRecommendationsUrl] = useState(false);
    const [activeTab, setActiveTab] = useState("");
    const [hotelRecommendation, setHotelRecommendation] = useState([]);

    const postList = posts?.map((post) => <Post post={post} key={post.id} type="feed" />);

    const usersList = users.map((user) => <UserFollow user={user} key={user.id} />);
    const searchedUsersList = searchedUsers.map((user) => <UserFollow user={user} key={user} />);

    const userLogged = getUser();
    const { t, i18n } = useTranslation();

    const [showSearchResults, setShowSearchResults] = useState(false);
    const searchInputRef = useRef(null);
    const searchResultsRef = useRef(null);
    const usernameRef = useRef(null);
    const[postalCode, setPostalCode] = useState('');
    const[city, setCity] = useState('');
    const[street, setStreet] = useState('');
    const[streetNumber, setStreetNumber] = useState('');
    const[state, setState] = useState('');
    const[country, setCountry] = useState('');
    const[lat, setLat] = useState('');
    const[long, setLong] = useState('');
    const[zip, setZip] = useState('');
    const locationRef = useRef()
    const[locationError, setLocationError] = useState('');
    const[usernameError, setUsernameError] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [clickedInsideUl, setClickedInsideUl] = useState(false);

    const isMobile = window.innerWidth <= 768;
    // },[user])

    const toggleVisibility = () => {
      setIsVisible(!isVisible);
    };
    useEffect(() => {
        if (window.location.href.indexOf("?favorites=true") != -1) {

            setFavoriteUrl(true);
            setActiveTab("Favorites");
            getPosts();
        } 
        else if (window.location.href.indexOf("?recommendations=true") != -1) {

          setRecommendationsUrl(true);
          setActiveTab("Recommendations");
          getRecommendetHotels();
      }
      else {
            setActiveTab("Recent");

            getPosts();
        }

       // getUsers();
       getUserFromUsername();

       function handleClickOutside(event) {

         //console.log(clickedInsideUl, event,event.target.tagName,event.target.id)

         /*if (
          searchInputRef.current &&
          !searchInputRef.current.contains(event.target) &&
          searchResultsRef.current &&
          !searchResultsRef.current.contains(event.target) &&
          !clickedInsideUl
        ) {
          console.log('clicking outside')
          // Clicked outside the search input and search results
          setShowSearchResults(false); // Hide the search results
        }*/
        if (!isMobile) {
          if (
            searchInputRef.current &&
            !searchInputRef.current.contains(event.target) &&
            searchResultsRef.current &&
            !searchResultsRef.current.contains(event.target)
          ) {
            console.log('clicking outside')
            // Clicked outside the search input and search results
            setShowSearchResults(false); // Hide the search results
          }
        } else {
           
            if (
              searchInputRef.current &&
              !searchInputRef.current.contains(event.target) &&
              searchResultsRef.current &&
              !searchResultsRef.current.contains(event.target) &&
              event.target.tagName != 'SPAN'
            ){
              //console.log('keep open')
              setShowSearchResults(false);
            }
        }
      }
  
      // Attach the event listener
      document.addEventListener('mousedown', handleClickOutside);
  
      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
        
    }, [favoriteUrl]);


    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    function GetLatlong(address) {
      var geocoder = new google.maps.Geocoder();
    
      geocoder.geocode({
        'address': address
      }, function(results, status) {
    
        if (status == google.maps.GeocoderStatus.OK) {
          let latitude = results[0].geometry.location.lat();
          let longitude = results[0].geometry.location.lng();
          
          getReverseGeocodingData(latitude, longitude);
    
        }
      });
    }
  
    function getReverseGeocodingData(lat, lng) {
      var latlng = new google.maps.LatLng(lat, lng);
      // This is making the Geocode request
     
      var geocoder = new google.maps.Geocoder();
      geocoder.geocode({ 'latLng': latlng }, function (results, status) {
          if (status !== google.maps.GeocoderStatus.OK) {
              alert(status);
          }
          // This is checking to see if the Geoeode Status is OK before proceeding
          if (status == google.maps.GeocoderStatus.OK) {
              console.log(results);
              results[0].address_components.forEach(function(element2){
                element2.types.forEach(function(element3){
  
                  switch(element3){
                    case 'postal_code':
                      setPostalCode(element2.long_name);
                      break;
                    case 'administrative_area_level_1':
                      setState(element2.long_name);
                      break;
                    case 'route':
                        setStreet(element2.long_name);
                        break;
                    case 'locality':
                      setCity(element2.long_name);
                      break;
                    case 'street_number':
                      setStreetNumber(element2.long_name);
                      break;
                    case 'country':
                      setCountry(element2.short_name);
                      break;
                  }
                })
              })
  
  
              setLat(lat);
              setLong(lng);
  
          }
      });
  
    }

    function onPlaceChanged(){
      GetLatlong(locationRef.current.value);
    }
  
    const { isLoaded } = useLoadScript({
      // googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      // googleMapsApiKey: 'AIzaSyCHtWbX8q4T_KTyTasNi9btO5fGoTZRuys',
      googleMapsApiKey: 'AIzaSyA-GPCiwhqR_u-g_sMWGSjcCpHE3LFzeWM',
      libraries: ['places'],
    });

    function showSuccessToast(message) {
      Swal.fire({
        title: 'Success!',
        text: 'Operation completed successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }

    function showErrorToast(message) {
      Swal.fire({
        title: 'Error!',
        text: 'Internal Error.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }

    function onPostCreate(e){
      e.preventDefault();
      setLocationError('');
      setUsernameError('');
      if(locationRef.current.value == ''){
        setLocationError('Location is required!');
        return;
      }

      if(userLogged.id==97 && usernameRef.current.value == ''){
        setUsernameError('Username is required!');
        return;
      }

      const formData = new FormData();
      //formData.append("caption", caption);
      formData.append("location", locationRef.current.value);
      formData.append("lat", lat);
      formData.append("long", long);
      formData.append("street", street);
      formData.append("zip", zip);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("country", country);
      formData.append("user_id", userLogged.id);
      formData.append("username", userLogged.username);
      formData.append("to_username", userLogged.id==97 ? usernameRef.current.value : '');
      axiosInstance
      .post(window.REACT_APP_API_ENDPOINT+"/api/saveHotelRecommendations", formData)
      .then((response) => {
        console.log(response);

        if(response.status == 200){
          showSuccessToast('Operation successful!');
          getRecommendetHotels();
          setIsVisible(!isVisible);
        } else {
          showErrorToast('An error occurred.');
        }
      })
      .catch((error) => {
        showErrorToast('An error occurred.');
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
    }

    async function getRecommendetHotels() {
      await axiosInstance
          .get(
              window.REACT_APP_API_ENDPOINT +
                  "/api/hotelRecommendations"
          )
          // axios.get('http://localhost:8000/api/posts')
          .then(function (response) {

              setHotelRecommendation(response.data);
          })
          .catch(function (error) {
              // handle error
              console.log(error);
          });
  }

  function getFollowers() {
    axiosInstance
        .get(
            window.REACT_APP_API_ENDPOINT +
                "/api/users/" +
                user.id +
                "/followers"
        )
        .then(function (response) {

            setFollowers(response.data);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
}
function getUserFromUsername() {
  axiosInstance
      .get(
          window.REACT_APP_API_ENDPOINT +
              "/api/users/username/" +
              userLogged.username
      )
      .then(function (response) {
          console.log("response user muse", response.data);
          setUser(response.data);
      })
      .catch(function (error) {
          // handle error
          console.log(error);
      });
}

function onUserModalClick() {
    setShowFollowersModal(false);
    setShowFollowingModal(false);
}

function onFollowersShow() {
    getFollowers();
    setShowFollowersModal(true);
    setShowFollowingModal(false);
}

function onFollowingShow() {
    getFollowing();
    setShowFollowersModal(false);
    setShowFollowingModal(true);
}

function getFollowing() {
    axiosInstance
        .get(
            window.REACT_APP_API_ENDPOINT +
                "/api/users/" +
                user.id +
                "/following"
        )
        .then(function (response) {

            setFollowing(response.data);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        });
}

  
    function getPosts() {
        if (favoriteUrl) {
            axiosInstance
                .get(window.REACT_APP_API_ENDPOINT + "/api/postfavorites/user")
                .then(function (response) {

                    setPosts(response.data);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        } else {
            axiosInstance
                .get(
                    window.REACT_APP_API_ENDPOINT +
                        "/api/users/followers/allposts"
                )
                // axios.get('http://localhost:8000/api/posts')
                .then(function (response) {

                    setPosts(response.data.posts);
                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                });
        }
    }

    function getUsers() {
        axiosInstance
            .get(
                window.REACT_APP_API_ENDPOINT +
                    "/api/users/" +
                    userLogged.id +
                    "/following"
            )
            .then(function (response) {

                setUsers(response.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            });
    }

    function handleSearchUserChange(e) {
      if (searchedUsersValue) {
        onSearchUserByUsername(searchedUsersValue);
      } else {
        setSearchedUsers([])
      }
      //onSearchUserByUsername(searchedUsersValue);
      /*if (e.key === 'Enter') {
        onSearchUserByUsername(searchedUsersValue);
      }*/
      
    }

    function onSearchUserByUsername(username) {
      axiosInstance
          .get(
              window.REACT_APP_API_ENDPOINT +
                  "/api/users/findbyusername/" +
                  username + "/"+userLogged.id
          )
          .then(function (response) {
              setSearchedUsers(response.data);
          })
          .catch(function (error) {
              // handle error
              console.log(error);
          });
  }

    // function onTabChange(e){
    //   console.log("valueee", e.target.value);
    //   if(e.target.value == 'recent'){
    //     window.location.assign('/en/feed')
    //   }
    //   if(e.target.value == 'favorites'){
    //     window.location.assign('/en/feed?favorites=true')
    //   }
    // }
    if (!isLoaded) return <div>Loading...</div>;

    else
    return (
        <div className="min-h-full">
            {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}

            <div className="py-10">
                <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
                    {/* <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
              <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
                <div className="pb-8 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50',
                        'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      <span className="truncate">{item.name}</span>
                    </a>
                  ))}
                </div>
                 <div className="pt-10">
                  <p
                    className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    id="communities-headline"
                  >
                    My communities
                  </p>
                  <div className="mt-3 space-y-2" aria-labelledby="communities-headline">
                    {communities.map((community) => (
                      <a
                        key={community.name}
                        href={community.href}
                        className="group flex items-center px-3 py-2 text-sm font-medium text-gray-600 rounded-md hover:text-gray-900 hover:bg-gray-50"
                      >
                        <span className="truncate">{community.name}</span>
                      </a>
                    ))}
                  </div>
                </div> 
              </nav>
            </div> */}
                    <main className="lg:col-span-7 xl:col-span-7">
                        <div className="px-4 sm:px-4">
                            <div className="sm:hidden">
                                <label
                                    htmlFor="question-tabs"
                                    className="sr-only"
                                >
                                    Select a tab
                                </label>
                                {/* <select
                    id="question-tabs"
                    className="block w-full rounded-md border-gray-300 text-base font-medium text-gray-900 shadow-sm focus:border-rose-500 focus:ring-rose-500"
                    onChange={onTabChange}
                  >
                  
             
                    <option value={"recent"}>{t('dashboard.tabs.recent')}</option>
                    <option value={"favorites"}>{t('dashboard.tabs.favorites')}</option>

                  </select> */}
                              <a
                                    href={"/en/feed?recommendations=true"}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primaryColor bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-6 mb-4 lg:mb-0"
                                >
                                    Travel Network
                                </a>
                                <a
                                    href={"/en/feed"}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primaryColor bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-6 mb-4 lg:mb-0"
                                >
                                    {t("dashboard.tabs.recent")}
                                </a>
                                <a
                                    href={"/en/feed?favorites=true"}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-primaryColor bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mb-4 lg:mb-0"
                                >
                                    {t("dashboard.tabs.favorites")}
                                </a>
                            </div>
                            <div className="hidden sm:block">
                                <nav
                                    className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                                    aria-label="Tabs"
                                >
                                    {/* {tabs.map((tab, tabIdx) => (
                      <a
                        key={tab.name}
                        href={tab.href}
                        aria-current={tab.current ? 'page' : undefined}
                        className={classNames(
                          tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                          tabIdx === 0 ? 'rounded-l-lg' : '',
                          tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                          'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                        )}
                      >
                        <span>{tab.name}</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            tab.current ? 'bg-rose-500' : 'bg-transparent',
                            'absolute inset-x-0 bottom-0 h-0.5'
                          )}
                        />
                      </a>
                    ))} */}
                                 <a
                                        key={"Recommendations"}
                                        href={"/en/feed?recommendations=true"}
                                        className={classNames(
                                            activeTab == "Recommendations"
                                                ? "text-gray-900"
                                                : "text-gray-500 hover:text-gray-700",

                                            "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                        )}
                                    >
                                        <span>
                                          Travel Network
                                        </span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                activeTab == "Recommendations"
                                                    ? "bg-rose-500"
                                                    : "bg-transparent",
                                                "absolute inset-x-0 bottom-0 h-0.5"
                                            )}
                                        />
                                    </a>

                                    <a
                                        key={"Recent"}
                                        href={"/en/feed"}
                                        className={classNames(
                                            activeTab == "Recent"
                                                ? "text-gray-900"
                                                : "text-gray-500 hover:text-gray-700",

                                            "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                        )}
                                    >
                                        <span>
                                            {t("dashboard.tabs.recent")}
                                        </span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                activeTab == "Recent"
                                                    ? "bg-rose-500"
                                                    : "bg-transparent",
                                                "absolute inset-x-0 bottom-0 h-0.5"
                                            )}
                                        />
                                    </a>

                                    <a
                                        key={"Favorites"}
                                        href={"/en/feed?favorites=true"}
                                        className={classNames(
                                            activeTab == "Favorites"
                                                ? "text-gray-900"
                                                : "text-gray-500 hover:text-gray-700",

                                            "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                                        )}
                                    >
                                        <span>
                                            {t("dashboard.tabs.favorites")}
                                        </span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                activeTab == "Favorites"
                                                    ? "bg-rose-500"
                                                    : "bg-transparent",
                                                "absolute inset-x-0 bottom-0 h-0.5"
                                            )}
                                        />
                                    </a>
                                </nav>
                            </div>
                        </div>
                        <div className="mt-4 px-4">
                            <h1 className="sr-only">Recent questions</h1>

                            <ul role="list" className="space-y-4">
                                {postList}
                            </ul>
                            <div className="">
                              <p className="text-right">
                              <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" onClick={toggleVisibility}>
                               
                                {userLogged.id==97 ? `+ Add Hotel for Users` : '+ My Hotels'}
                              </button>
                              </p>                
                            </div>
                            {isVisible && (
                            <form>
                              <div className="bg-white rounded-lg shadow mt-100 py-5 px-5 mb-4">
                                <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                                  Hotel
                                </label>
                                <div className="mt-1">
                                <Autocomplete onPlaceChanged={onPlaceChanged}>
                                  <input
                                    id="location"
                                    type="text"
                                    name="location"
                                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Location"
                                    ref={locationRef}
                                    />
                                  </Autocomplete>
                                  {locationError && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {locationError}
                                    </span>}
                                  {userLogged.id ==97 && (
                                    <div>
                                      <input
                                        id="username"
                                        type="text"
                                        name="username"
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                        placeholder="@username"
                                        ref={usernameRef}
                                        />
                                    </div>
                                  )} 
                                    {usernameError && <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                                        {usernameError}
                                    </span>}
                                </div>
                                <div className="px-0 py-3 text-right">
                                  <button
                                    type="submit"
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primaryColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={onPostCreate}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </form>
                            )}
                            <div className="lg:col-span-5 xl:col-span-5 lg:hidden md:hidden 2xl:hidden xl:hidden sm:block">
                        <div className="sticky top-4 space-y-4">
                            <section aria-labelledby="who-to-follow-heading" >
                              <div className="bg-white rounded-lg shadow py-4 px-5 mb-4">
                              <div className="flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                                                <button
                                                    onClick={onFollowersShow}
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                >
                                                    {user.followers}
                                                    <span>
                                                      &nbsp;  {t("user.followers")}
                                                    </span>
                                                </button>
                                                <button
                                                    onClick={onFollowingShow}
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                >
                                                    {user.following}
                                                    <span>
                                                        &nbsp; {t("user.following")}
                                                    </span>
                                                </button>
                                            </div>
                              </div>
                                <div className="bg-white rounded-lg shadow py-5 px-5 mb-4">
                                <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
                                  Find User
                                </label>
                                  <div className="relative mt-2 flex items-center">
                                    <input
                                      type="text"
                                      name="search"
                                      id="search"
                                      ref={searchInputRef}
                                      onKeyUp={handleSearchUserChange}
                                      onChange={(e)=>setSearchedUsersValue(e.target.value)}
                                      //onChange={(e)=>setSearchedUsersValue(e.target.value)}
                                      value={searchedUsersValue}
                                      
                                      onClick={() => setShowSearchResults(true)}
                                      className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                   
                                  </div>
                                  <div className="mt-6 flow-root overflow-y-scroll max-h-52" ref={searchResultsRef}>
                                  {showSearchResults && Array.isArray(searchedUsers) && (
                                     
                                          <ul
                                              role="list"
                                              className="-my-4 divide-y divide-gray-200"
                                              onClick={() => setClickedInsideUl(true)}
                                          >
                                        
                                            {searchedUsersList}
                                          </ul>
                                  )}
                                      
                                  </div>
                                </div>
                                <div className="bg-white rounded-lg shadow" style={{display: 'none' }}>
                                    <div className="p-6">
                                        <h2
                                            id="who-to-follow-heading"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {t("dashboard.who_you_follow")}
                                        </h2>
                                        <div className="mt-6 flow-root overflow-y-scroll max-h-52">
                                            {usersList.length > 0 ? (
                                                <ul
                                                    role="list"
                                                    className="-my-4 divide-y divide-gray-200"
                                                >
                                                    {usersList}
                                                </ul>
                                            ) : (
                                                "You don't follow anyone"
                                            )}
                                        </div>
                                       
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                            {hotelRecommendation.length>0 && <HotelRecommendation
                            hotels={hotelRecommendation}/>}
                        </div>
                    </main>
                    {showFollowersModal && (
                            <UserListModal
                                users={followers}
                                type="Followers"
                                onUserModalClick={onUserModalClick}
                            />
                        )}
                        {showFollowingModal && (
                            <UserListModal
                                users={following}
                                type="Following"
                                onUserModalClick={onUserModalClick}
                            />
                        )}
                    <aside className="lg:col-span-5 xl:col-span-5 hidden sm:hidden xl:block 2xl:block lg:block md:block">
                        <div className="sticky top-4 space-y-4">
                            <section aria-labelledby="who-to-follow-heading" >
                              <div className="bg-white rounded-lg shadow py-4 px-5 mb-4">
                              <div className="flex flex-col justify-stretch space-y-3 sm:flex-row sm:space-y-0 sm:space-x-4">
                                                <button
                                                    onClick={onFollowersShow}
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                >
                                                    {user.followers}
                                                    <span>
                                                      &nbsp;  {t("user.followers")}
                                                    </span>
                                                </button>
                                                <button
                                                    onClick={onFollowingShow}
                                                    type="button"
                                                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
                                                >
                                                    {user.following}
                                                    <span>
                                                        &nbsp; {t("user.following")}
                                                    </span>
                                                </button>
                                            </div>
                              </div>
                                <div className="bg-white rounded-lg shadow py-5 px-5 mb-4">
                                <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
                                  Find User
                                </label>
                                  <div className="relative mt-2 flex items-center">
                                    <input
                                      type="text"
                                      name="search"
                                      id="search"
                                      ref={searchInputRef}
                                      onKeyUp={handleSearchUserChange}
                                      //onChange={(e)=>setSearchedUsersValue(e.target.value)}
                                      onChange={(e)=>setSearchedUsersValue(e.target.value)}
                                      value={searchedUsersValue}
                                      
                                      onClick={() => setShowSearchResults(true)}
                                      className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                    {/*<div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                      <kbd className="inline-flex items-center rounded border border-gray-200 px-1 font-sans text-xs text-gray-400">
                                        ⌘K
                                      </kbd>
                                    </div>*/}
                                  </div>
                                  <div className="mt-6 flow-root overflow-y-scroll max-h-52" ref={searchResultsRef}>
                                  {showSearchResults && Array.isArray(searchedUsers) && (
                                     
                                          <ul
                                              role="list"
                                              className="-my-4 divide-y divide-gray-200"
                                              onClick={(e) => e.stopPropagation()}
                                          >
                                            
                                            {searchedUsersList}
                                          </ul>
                                  )}
                                      {/* ) : (
                                          "No results"
                                      )} */}
                                  </div>
                                </div>
                                <div className="bg-white rounded-lg shadow" style={{display: 'none' }}>
                                    <div className="p-6">
                                        <h2
                                            id="who-to-follow-heading"
                                            className="block text-sm font-medium leading-6 text-gray-900"
                                        >
                                            {t("dashboard.who_you_follow")}
                                        </h2>
                                        <div className="mt-6 flow-root overflow-y-scroll max-h-52">
                                            {usersList.length > 0 ? (
                                                <ul
                                                    role="list"
                                                    className="-my-4 divide-y divide-gray-200"
                                                >
                                                    {usersList}
                                                </ul>
                                            ) : (
                                                "You don't follow anyone"
                                            )}
                                        </div>
                                        {/* <div className="mt-6">
                        <a
                          href="#"
                          className="w-full block text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          View all
                        </a>
                      </div> */}
                                    </div>
                                </div>
                            </section>
                            {/* <section aria-labelledby="trending-heading">
                  <div className="bg-white rounded-lg shadow">
                    <div className="p-6">
                      <h2 id="trending-heading" className="text-base font-medium text-gray-900">
                        Trending
                      </h2>
                      <div className="mt-6 flow-root">
                        <ul role="list" className="-my-4 divide-y divide-gray-200">
                          {trendingPosts.map((post) => (
                            <li key={post.id} className="flex py-4 space-x-3">
                              <div className="flex-shrink-0">
                                <img className="h-8 w-8 rounded-full" src={post.user.imageUrl} alt={post.user.name} />
                              </div>
                              <div className="min-w-0 flex-1">
                                <p className="text-sm text-gray-800">{post.body}</p>
                                <div className="mt-2 flex">
                                  <span className="inline-flex items-center text-sm">
                                    <button
                                      type="button"
                                      className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                                    >
                                      <ChatAltIcon className="h-5 w-5" aria-hidden="true" />
                                      <span className="font-medium text-gray-900">{post.comments}</span>
                                    </button>
                                  </span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-6">
                        <a
                          href="#"
                          className="w-full block text-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                        >
                          View all
                        </a>
                      </div>
                    </div>
                  </div>
                </section> */}
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    );
}
